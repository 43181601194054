.main-gallery {
  display: flex;
  flex-wrap: wrap;

  margin-right: -15px;
  margin-left: -15px;


  @include media-breakpoint-down(xs) {
    margin-right: -10px;
    margin-left: -10px;
  }

  a {
    position: relative;
    width: 33.3%;

    padding: 15px;

    color: #fff;
    text-decoration: none;

    @include media-breakpoint-down(sm) {
      width: 50%;
    }

    @include media-breakpoint-down(xs) {
      width: 100%;

      padding: 10px;
    }

    &:before {
      content: "\f067";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: flex;
      align-items: center;
      justify-content: center;

      width: 50px;
      height: 50px;

      background-color: $main-color;
      font-family: "Font Awesome 5 Pro";

      box-shadow: 0 0 0 8px transparentize(#fff, .3);
      border-radius: 50%;

      opacity: 0;
      transition: 0.3s;
    }


    &:hover:before, &:active:before, &:focus:before {
      opacity: 1;
    }

    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
      margin: 0 auto;
      object-fit: cover;
      object-position: center;

      font-family: " object-fit: cover;  object-position: center;";
    }
  }


}