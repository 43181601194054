//Section promo
.promo {
  position: relative;
  min-height: 100vh;
/*  height: 100%;*/

  padding-top: 335px;
  padding-bottom: 250px;

  background-image: url("../img/promo-bg.jpg");
  background-color: #000;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(md) {
    padding-top: 150px;
    padding-bottom: 170px;
  }

  @include media-breakpoint-down(sm) {
    padding-top: 130px;
    padding-bottom: 220px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);

    width: 13%;
    height: 2px;
    background-color: $accent-color;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__item {
    position: relative;

  }

  &__inner {
    width: 700px;
    max-width: 100%;

    background-image: url("../img/promo-inner-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;

      background-image: none;
    }

    @include media-breakpoint-down(sm) {
      text-align: center;
    }

    h1 {
      margin-bottom: 10px;

      @include media-breakpoint-down(md) {
        font-size: 45px;

        margin-bottom: 20px;
      }
    }
  }

  &__description {
    width: 640px;
    max-width: 100%;

    margin-bottom: 40px;
    padding: 20px;

    background-color: transparentize(#000, .5);
    border-radius: 10px;


    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    p {
      color: #fff;
    }

    .text-invisible {
      display: none;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .snail {
      width: 242px;
      max-width: 100%;
    }


  }

  &__more {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;

    display: flex;
    justify-content: center;

    min-height: 350px;
    width: 390px;
    max-width: 100%;

    padding: 50px 0 50px 20px;

    background-image: url("../img/promo-more-bg.png");;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @include media-breakpoint-down(lg) {
      width: 300px;
      padding: 40px 0 20px 10px
    }

    @include media-breakpoint-down(md) {
      position: relative;
      top: auto;
      right: auto;
      transform: translateY(0);

      width: 300px;
      min-height: auto;
      padding: 30px 15px;
      margin: 0 auto;

      background-image: none;
      background-color: #fff;

      border-radius: 10px;
    }
  }

  &__more-inner {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }

    i {
      margin-right: 20px;
      width: 30px;

      font-size: 30px;
      line-height: 1;

      color: $main-color;
    }
  }

  &__more-info {
    span {
      display: block;

      font-size: 14px;
      font-weight: 600;
    }

    a {
      @extend .hover-line;
      @extend .green;

      font-size: 12px;
      text-transform: uppercase;
      color: $main-color;

      transition: 0.3s;
    }
  }

  &__date {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: transparentize($main-color, .2);

    @include media-breakpoint-down(sm) {
      text-align: center;
    }
  }

  &__date-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    padding: 22px 0;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    .name {
      margin-right: 25px;
      color: #fff;
      font-size: 18px;
      font-weight: 700;

      @include media-breakpoint-down(md) {
        width: 100%;

        margin-bottom: 15px;
      }
    }
  }

  &__date-choice {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(sm) {
      width: 100%;
      justify-content: center;

      margin-bottom: 15px;
    }
  }

  &__date-input {
    position: relative;

    width: 300px;

    @include media-breakpoint-down(lg) {
      width: 200px;
    }

    @include media-breakpoint-down(sm) {
      width: 150px;
    }

    &:not(:last-child) {
      margin-right: 35px;

      @include media-breakpoint-down(sm) {
        margin-right: 15px;
      }
    }

    input {
      width: 100%;
      height: 55px;

      padding: 0 20px;

      font-size: 14px;
      font-weight: 700;

      border: none;
      border-radius: 6px;

      @include media-breakpoint-down(lg) {
        padding: 10px;

        font-size: 11px
      }
    }

    .text {
      position: absolute;
      left: 20px;
      top: 3px;

      font-size: 12px;
      text-transform: uppercase;
      color: $main-color;
      font-weight: 700;
    }

    i {
      position: absolute;

      right: 20px;
      top: 50%;
      transform: translateY(-50%);

      color: $main-color;

      @include media-breakpoint-down(sm) {
        right: 5px;
        top: 10px;

        font-size: 12px;
      }
    }
  }
}


//Section banner
.banner {
  display: block;
  height: 905px;
  background-image: url("../img/promo-bg-1.jpg");
  background-color: #fff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: contain;

  @include media-breakpoint-down(lg) {
    height: 600px;
  }

  @include media-breakpoint-down(md) {
    height: 400px;
  }

  @include media-breakpoint-down(sm) {
    height: 250px;
  }
}

//Section about-us
.about-us {
  padding: 100px 0;

  text-align: center;

  background-image: url("../img/about-us-bg.jpg");
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 0;
  }

  h2 {
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {

      margin-bottom: 20px;
    }

    .color {
      display: block;

      color: $main-color;
    }

    .snail {
      width: 752px;
      max-width: 100%;

      margin-right: 0;
    }
  }

  &__text {
    margin-bottom: 60px;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    p {
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          margin-bottom: 20px;
        }
      }

      &.bold {
        font-weight: 700;
        font-size: 16px;
      }
    }

    .snail {
      width: 242px;
    }

  }

  &__more {
    text-align: center;
  }


}

//Section advantages
.advantages {
  padding: 165px 0 100px 0;
  margin-top: -65px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;


  @include media-breakpoint-down(md) {
    padding: 90px 0 50px 0;
    margin-top: -40px;
  }

  @include media-breakpoint-down(sm) {
    padding: 70px 0 30px 0;
    margin-top: -30px;
  }

  h2 {
    color: #fff;
    margin-bottom: 30px;
  }

  &__slider {
    &.owl-carousel {
      .owl-nav {
        width: 100%;
        height: 0;
        background-color: $main-color;

        button {
          display: block;
          position: absolute;
          top: 44.5%;
          transform: translateY(-50%);
          box-sizing: border-box;

          width: 70px; // обертка для стрелки, например фон
          height: 70px; // обертка для стрелки, например фон

          background-color: $main-color;

          border-radius: 50%;

          transform-origin: center center;

          outline: none;

          transition: 0.3s;

          @include media-breakpoint-down(sm) {
            top: 30%;

            width: 40px;
            height: 40px;
          }

          span {
            display: none;
          }

          &:hover:before, &:active:before, &:focus:before {
            border-color: $accent-color;
            border-top-color: transparent;
            border-right-color: transparent;
          }

          &:before {
            content: "";

            position: absolute;
            top: calc(50% - 9px); // половина от ширины псевдоэлемента
            transform: translateY(-50%);

            width: 18px;
            height: 18px;
            background-color: transparent;
            color: #fff;

            border: 5px solid #fff;
            border-radius: 4px;

            border-top-color: transparent;
            border-right-color: transparent;

            transition: 0.3s;
          }
        }

        .owl-prev {
          left: -35px;

          @include media-breakpoint-down(sm) {
            left: -5px;
          }

          &:before {
            transform: translateX(-50%) rotate(45deg);
            left: 50%; // шестая часть от ширины псевдоэлемента;
          }
        }

        .owl-next {
          right: -35px;

          @include media-breakpoint-down(sm) {
            right: -5px;
          }

          &:before {
            transform: translateX(-50%) rotate(225deg);
            left: 50%; // шестая часть от ширины псевдоэлемента
          }
        }
      }

    }
  }

  &__slide {
    figure {
      margin-bottom: 0;

      border-radius: 10px;

      img {
        border-radius: 10px;

        display: block;
        max-width: 100%;
        width: auto;
        height: 565px;

        object-fit: cover;
        object-position: center;

        font-family: "object-fit: cover;  object-position: center;";

        @include media-breakpoint-down(lg) {
            height: 400px;
        }

        @include media-breakpoint-down(md) {
          height: 300px;
        }

        @include media-breakpoint-down(sm) {
          height: 2-300px;
        }

      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }
    }
  }


  &__slider-dots {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 70px;

    overflow-x: auto;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  &__slider-dot {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 186px;
    height: 45px;

    padding: 15px;


    &:not(:last-child) {
      margin-right: 20px;

      @include media-breakpoint-down(md) {
        margin-right: 15px;
      }
    }

    color: #fff;

    border: 1px solid #fff;
    border-radius: 23px;

    cursor: pointer;

    @include media-breakpoint-down(lg) {
      width: 138px;
      padding: 15px 10px;
    }

    @include media-breakpoint-down(md) {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 5px;
    }


    &.active {
      background-color: #fff;
      border-color: #fff;
      color: $main-color;
    }

    i {
      margin-right: 10px;
      @include media-breakpoint-down(md) {
        margin-right: 0;
      }
    }

    span {
      font-size: 12px;
      font-weight: 700;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    transition: 0.3s;

    &:hover, &:active, &:focus {
      color: $accent-color;
      border-color: $accent-color;
    }
  }

  &__slide-info {
    position: relative;
    width: 660px;
    max-width: 100%;
    z-index: 1;

    margin: -140px auto 0 auto;
    padding: 50px 0;

    text-align: center;

    background-color: #fff;

    border-radius: 10px;

    @include media-breakpoint-down(md) {
      width: 400px;

      margin: -100px auto 0 auto;
      padding: 30px;
    }

    @include media-breakpoint-down(sm) {
      width: 300px;

      margin: -30px auto 0 auto;
      padding: 20px;
    }

    h3 {
      margin-bottom: 20px;
    }
  }
}

//Section offers
.offers {
  padding: 100px 0;

  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 0;
  }

  h2 {
    margin-bottom: 60px;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }
  }

  &__slider {
    padding-bottom: 130px;

    @include media-breakpoint-down(md) {
      padding-bottom: 80px;
    }

    &.owl-carousel {
      .owl-nav {
        width: 100%;
        height: 0;
        background-color: $main-color;

        button {
          display: block;
          position: absolute;
          bottom: 0;
          box-sizing: border-box;
          z-index: 1;

          width: 46px; // обертка для стрелки, например фон
          height: 46px; // обертка для стрелки, например фон

          border: 1px solid $border-color;

          border-radius: 50%;

          transform-origin: center center;

          outline: none;
          cursor: pointer;

          transition: 0.3s;

          span {
            display: none;
          }

          &:hover, &:active, &:focus {
            border-color: $main-color;
          }

          &:hover:before, &:active:before, &:focus:before {
            border-color: $main-color;
            border-top-color: transparent;
            border-right-color: transparent;
          }

          &:before {
            content: "";

            position: absolute;
            top: calc(50% - 5px); // половина от ширины псевдоэлемента
            transform: translateY(-50%);

            width: 10px;
            height: 10px;
            background-color: transparent;
            color: #fff;

            border: 2px solid $gray-color;
            border-radius: 2px;

            border-top-color: transparent;
            border-right-color: transparent;

            transition: 0.3s;
          }
        }

        .owl-prev {
          left: 0;

          &:before {
            transform: translateX(-50%) rotate(45deg);
            left: 50%; // шестая часть от ширины псевдоэлемента;
          }
        }

        .owl-next {
          right: 0;

          &:before {
            transform: translateX(-50%) rotate(225deg);
            left: 50%; // шестая часть от ширины псевдоэлемента
          }
        }
      }

      .owl-dots {
        position: absolute;
        bottom: 17px;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        button {
          width: 14px;
          height: 14px;

          &:not(:last-child) {
            margin-right: 20px;
          }

          border-radius: 50%;
          border: 1px solid $border-color;

          outline: none;

          &.active {
            background-color: $gray-dark-color;
          }
        }

      }

    }
  }

  &__slide-img {
    position: relative;

    display: block;

    color: $text-color;
    text-decoration: none;

    overflow: hidden;

    transition: 0.3s;

    &:hover, &:active, &:focus {
      text-decoration: none;
      color: $main-color;
    }

    &:hover figure, &:active figure, &:focus figure {
      transform: scale(1.05);
    }

    figure {
      margin: 0;

      transition: 0.3s;
    }

    img {
      display: block;
    }
  }

  &__slide-date {
    position: relative;
    z-index: 1;

    display: inline-flex;
    align-items: center;

    padding: 30px 80px 30px 0;
    margin-top: -75px;

    background-color: #fff;

    i {
      margin-right: 15px;
    }

    span {
      font-size: 12px;
      font-weight: 300;
    }
  }

  &__slide-text {
    margin-bottom: 40px;

    p {
      margin-bottom: 0;

      font-size: 18px;
    }
  }

  &__slide-link {
    display: flex;
    align-items: center;

    color: $main-color;

    a {
      @extend .hover-line;
      @extend .green;

      margin-right: 10px;

      font-size: 14px;
      font-weight: 700;
      color: $main-color;
    }
  }
}

//Section contacts
.contacts {

  padding: 100px 0;

  background-image: url("../img/contacts-bg.jpg");
  background-color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(md) {
    padding: 50px;
  }

  @include media-breakpoint-down(sm) {
    padding: 30px 0;
  }

  &__item {
    position: relative;

  }

  &__map {
    width: 100%;
    height: 500px;

    border-radius: 10px;

    @include media-breakpoint-down(sm) {
      height: 300px;

      margin-bottom: 20px;
    }

    * {
      border-radius: 10px;
    }
  }

  &__info {
    position: absolute;
    top: 60px;
    left: 60px;

    width: 480px;
    max-width: 100%;

    padding: 50px 40px;

    background-image: url("../img/contacts-item-bg.png");
    background-color: #fff;
    background-position: right 20px bottom 50px;
    background-repeat: no-repeat;
    background-size: auto;

    border-radius: 10px;

    @include media-breakpoint-down(md) {
      top: 30px;
      left: 30px;

      width: 350px;

      padding: 20px;
    }

    @include media-breakpoint-down(sm) {
      position: static;

      margin: 0 auto;
    }

    h3 {
      text-align: left;

      margin-bottom: 20px;
    }
  }

  &__inner {
    margin-bottom: 30px;
  }

  &__address {
    display: flex;

    margin-bottom: 20px;

    i {
      margin-right: 20px;

      font-size: 23px;
      line-height: 1.5;
      color: $gray-color;
    }

    span {

    }
  }

  &__tel {
    @extend .hover-line;
    @extend .green;

    display: inline-flex;
    align-items: center;

    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 20px;
      height: 20px;

      margin-right: 20px;

      border-radius: 50px;
      background-color: $gray-color;

      i {
        font-size: 10px;
        line-height: 1;
        color: #fff;
      }
    }

    color: $text-color;
    font-size: 18px;
    font-weight: 700;
  }

}

.no-webp {
  .advantages {
    background-image: url("../img/advabtages-bg.png");
  }
}

.webp {
  .advantages {
    background-image: url("../img/advabtages-bg.webp");
  }
}



