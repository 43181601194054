.about {
  background-position: left 300px, right 300px;

  &__welcome {
    margin-bottom: 80px;
    padding: 0 150px;

     background-image: url(../img/branch-bg-left.png),url(../img/branch-bg-right.png);
       background-size: auto,auto;
       background-repeat: no-repeat;
       background-position: left center,right center;

    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
      padding: 0;
      background: none;
    }

    .about-us {
      background: none;
      padding: 0;
    }

    .about-us__text {
      margin-bottom: 0;
    }
  }

  &__slider {
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
      margin-bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
      margin-bottom: 30px;
    }
  }

  &_sum {
    padding: 90px 0;
    margin-bottom: 115px;

    background-color: #fff;

    @include media-breakpoint-down(md) {
      padding: 40px 0;
      margin-bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
      margin-bottom: 30px;
    }
  }

  &__sum-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex-wrap: wrap;
  }

  &__sum-item {
    width: 16.666%;

    text-align: center;

    &:not(:last-child) {
      border-right: 1px solid $border-color;

      @include media-breakpoint-down(xs) {
        padding-bottom: 10px;

        border-right: 0;

        border-bottom:  1px solid $border-color;;
      }
    }

    span {
      display: block;

      &.number {
        font-family: $title-font;
        font-size: 92px;
        line-height: 1;
        color: $main-color;

        @include media-breakpoint-down(md) {
          font-size: 70px;
        }

        @include media-breakpoint-down(md) {
          font-size: 50px;
        }

        @include media-breakpoint-down(xs) {
          font-size: 40px;
        }
      }

      &.text {
        font-size: 14px;
      }
    }

    @include media-breakpoint-down(md) {
      width: 33.333%;

      margin-bottom: 20px;

      border-right: 1px solid $border-color;
    }

    @include media-breakpoint-down(sm) {
      width: 50%;

    }

    @include media-breakpoint-down(xs) {
      width: 100%;
      border-right: 0;
    }

  }

  &__features {

    .sub-title {
      justify-content: center;

      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
  }

  &__features-item-box {
    margin-bottom: 70px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  &__features-item {
    position: relative;
    height: 100%;

    display: flex;
    flex-direction: column;

    padding: 20px 20px 40px 20px;

    background-color: #fff;
    border-radius: 10px;

    @include media-breakpoint-down(md) {
      padding: 20px;
    }

    @include media-breakpoint-down(sm) {
      padding: 15px;
    }
  }

  &__features-description {
    margin-bottom: 20px;

    p {
      margin-bottom: 0;

      font-size: 14px;
    }
  }

  &__features-icon {
    position: absolute;
    left: 50%;
    bottom: -38px;
    transform: translateX(-50%);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 76px;
    height: 76px;

    padding: 17px;

    background-color: $main-color;
    border-radius:50%;

    svg {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;

      fill: #fff;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      margin: 0;
    }

    @include media-breakpoint-down(md) {
      position: static;
      transform: translateX(0);

      margin-top: auto;

      align-self: center;
    }

    @include media-breakpoint-down(sm) {
      width: 50px;
      height: 50px;

      padding: 10px;
    }
  }

  .advantages {
    margin-bottom: 0;
  }

}