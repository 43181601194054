.footer {
  &__top {
    padding: 80px 0;

    border-bottom: 1px solid transparentize($border-color, .5);

    @include media-breakpoint-down(md) {
      padding: 50px 0;
    }

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
    }
  }

  &__top-item {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__logo {
    display: block;
    flex: none;

    width: 218px;

    margin-right: 125px;

    @include media-breakpoint-down(lg) {
      margin-right: 40px;
    }

    @include media-breakpoint-down(md) {
      width: 150px;
    }

    @include media-breakpoint-down(sm) {
        margin-right: 0;
      margin-bottom: 20px;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  &__nav {
    display: flex;
    flex: none;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    ul {
      width: 230px;

      padding: 0;
      margin: 0;
      list-style: none;

      @include media-breakpoint-down(lg) {
        width: 190px;
      }


      &:first-child {
        padding-right: 95px;
        margin-right: 60px;

        border-right: 1px solid transparentize($border-color, .5);

        @include media-breakpoint-down(lg) {
          padding-right: 40px;
          margin-right: 40px;
        }

        @include media-breakpoint-down(xs) {
          padding-right: 0;
          margin-right: 0;

          border: none;
        }
      }
    }

    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    a {
      @extend .hover-line;
      @extend .green;

      color: $text-color;
      font-size: 14px;
    }
  }

  &__contacts {
    text-align: right;

    @include media-breakpoint-down(md) {
      width: 100%;

      margin-top: 20px;
      text-align: center;
    }
  }

  &__address {
    display: block;

    margin-bottom: 20px;

    font-size: 14px;
    font-weight: 700;
    text-align: right;

    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }

  &__tel {
    @extend .hover-line;
    @extend .green;

    display: inline-block;

    margin-bottom: 30px;

    font-size: 22px;
    font-weight: 700;
    text-align: right;
    color: $text-color;

    &:nth-of-type(1) {
      margin-bottom: 0;
    }

    @include media-breakpoint-down(md) {
      margin-right: 20px;
    }
  }

  &__bottom {
    padding: 35px 0;

    @include media-breakpoint-down(md) {
      padding: 20px 0;
    }
  }

  &__bottom-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
     flex-direction: column;
    }

    figure {
      margin: 0;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  &__copyright {
    font-size: 12px;

    @include media-breakpoint-down(md) {
      width: 100%;

      margin-bottom: 15px;
      text-align: center;
    }
  }

  &__pay {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      justify-content: center;

      width: 100%;

      margin-bottom: 15px;
    }

    span {
      margin-right: 30px;

      font-size: 12px;

      @include media-breakpoint-down(md) {
        margin-right: 15px;
      }
    }
  }

  &__partners {

  }

  &__reviews {
    display: flex;
    align-items: center;

    margin-bottom: 20px;
    a {
      transition: .3s ease;
      &.top-hotels {
        width: 85px;
        height: 30px;
        background: url(../img/rating-sprite.png) 0 7px no-repeat;
        margin-right: 30px;

        &:hover, &:active, &:focus {
          background-position: 0 -43px;
        }
      }
      &.trip-advisor {
        width: 145px;
        height: 30px;
        background: url(../img/rating-sprite.png) -116px 3px no-repeat;
        &:hover, &:active, &:focus {
          background-position: -116px -45px;
        }
      }
    }
  }


}