@font-face {
  font-family: "Roboto";
  src: url("../fonts/RobotoThin.woff") format("woff");
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/RobotoLight.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/RobotoRegular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/RobotoMedium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/RobotoBold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "JOURNALISM";
  src: url("../fonts/JOURNALISM.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}