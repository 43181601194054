.contacts-page {
  padding: 0 0 100px 0;

  background-image: none;

  @include media-breakpoint-down(md) {
    padding: 0 0 50px 0;
  }

  &__top {
    position: relative;

    padding: 0 0 110px 0;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    .container {
      position: relative;
      z-index: 1;

      background-color: $page-bg;
    }

    &:before {
      content: "";
      position: absolute;
      top: 20px;
      left: 0;
      width: 15%;
      height: 2px;

      z-index: 0;

      background-color: #f6bf40;
    }
  }

  &__map {
    position: absolute;
    top: -229px;
    right: 0;
    height: calc(100% + 229px);
    width: 50%;
    z-index: 100;

    @include media-breakpoint-down(md) {
      position: static;
      width: 100%;
      height: 300px;
      top: auto;
      right: auto;

      margin-top: 30px;
    }
  }

  &__address {
    display: block;

    margin-bottom: 30px;

    font-size: 18px;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__item-name {
    display: flex;
    align-items: center;

    margin-right: 20px;


    > i {
      color: $main-color;
      font-size: 18px;
      margin-right: 25px;
      margin-left: 5px;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }
    }

    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 20px;
      height: 20px;

      margin-right: 20px;

      background-color: $main-color;
      border-radius: 50%;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
      }

      i {
        color: #fff;
        line-height: 1;
        font-size: 10px;
      }
    }

    .name {
      min-width: 70px;
      font-weight: bold;

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &__item-desc {
    a {
      @extend .hover-line, .green;

      color: $main-color;
      text-decoration: underline;

      @include media-breakpoint-down(sm) {
        font-size: 14px;
      }
    }

    span {
      font-size: 14px;
    }
  }

  &__office {
    padding: 100px 0;

    margin-bottom: 70px;

    @include media-breakpoint-down(md) {
      padding: 50px 0;
      margin-bottom: 40px;
    }

    @include media-breakpoint-down(sm) {
      padding: 30px 0;
      margin-bottom: 30px;
    }

    background-color: #fff;
  }

  &__office-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
  }

  &__office-title {
    width: 220px;

    padding-right: 50px;

    @include media-breakpoint-down(lg) {
      padding-right: 20px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 20px;

      br {
        display: none;
      }
    }

    h2 {
      text-align: left;
    }
  }

  &__office-item {
    padding: 10px 50px;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;

    @include media-breakpoint-down(lg) {
      padding: 10px 20px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;

      margin-bottom: 20px;
    }

    @include media-breakpoint-down(sm) {
     padding: 0;

      border-right: none;
      border-left: none;
    }
  }

  &__office-button {
    padding-left: 50px;

    @include media-breakpoint-down(lg) {
      padding-left: 20px;
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }

  &__route {
    .sub-title {
      justify-content: center;

      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
  }

  &__route-col {
    &:not(:last-child) {
      border-right: 1px solid $border-color;

      @include media-breakpoint-down(sm) {
        border-right: none;
      }
    }

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

  }

  &__route-item {
    .title {
      display: flex;
      align-items: center;

      margin-bottom: 20px;
      padding-right: 15px;

      span {
        font-weight: 600;

        margin-right: 20px;
      }

      i {
        font-size: 30px;
        line-height: 1;

        color: $main-color;
      }
    }

    p {
      margin-bottom: 0;

      font-size: 14px;
    }
  }
}