.prices {

  &__inner {
    &:not(:last-child) {
      margin-bottom: 45px;

      @include media-breakpoint-down(md) {
        margin-bottom: 25px;
      }
    }

    h3 {
      margin-bottom: 25px;

      font-family: $default-font;
      font-size: 18px;
      text-align: left;
      font-weight: bold;
    }
  }
}