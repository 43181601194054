.services {


  &__item {
    display: flex;
    align-items: center;

    padding: 30px;

    &:not(:last-child) {
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }

    background-color: #fff;
    border-radius: 10px;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      padding: 15px;
    }
  }

  &__img {
    @extend .scale-img;

    width: calc(35% - 30px);

    margin-right: 30px;

    @include media-breakpoint-down(md) {
      width: 100%;

      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__info {
    width: 65%;

    @include media-breakpoint-down(md) {
      width: 100%;

      text-align: center;
    }
  }

  &__title {
    @extend .hover-line, .green;

    display: inline-block;

    margin-bottom: 20px;

    font-size: 18px;
    font-weight: bold;
    color: #000;
  }

  &__description {
    margin-bottom: 25px;

    font-size: 14px;
  }
}