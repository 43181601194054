$default-font: "Roboto", sans-serif;
$title-font: "JOURNALISM", serif;
$main-color: #009640;
$accent-color:  #fcd975;
$text-color: #000;
$text-color-light: #4a4a4a;
$text-disable: #d2d2d2;
$icon-color:  #7ee096;
$border-color: #ccc;
$gray-color: #9e9e9e;
$gray-dark-color: #707070;
$page-bg: #f6f6f6;





$btn-color: linear-gradient(to top, #f6bd3a 0%, #fedf84 100%);
$btn-color-hover: linear-gradient(to top, #fedf84 0%, #f6bd3a 100%);

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1260px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1230px);

$gutter: $grid-gutter-width;
