.placement {

  &__item {
    &:not(:last-child) {
      margin-bottom: 50px;

      @include media-breakpoint-down(md) {
        margin-bottom: 25px;
      }
    }
  }

  &__card {
    @extend .shadow-l;

    background-color: #fff;
    border-radius: 10px;

    &:not(:last-child) {
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
  }

  &__card-top {
    display: flex;

    padding: 30px 50px 30px 30px;

    align-items: flex-start;

    border-bottom: 1px solid $border-color;

    @include media-breakpoint-down(lg) {
      padding: 20px;
    }

    @include media-breakpoint-down(md) {
      padding: 15px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  &__card-img {
    display: block;
    width: calc(53% - 40px);

    margin-right: 40px;

    color: #000;
    text-decoration: none;

    overflow: hidden;

    @include media-breakpoint-down(sm) {
     width: 100%;

      margin-right: 0;
      margin-bottom: 20px;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;

      transition: 0.3s;
    }

    &:hover img, &:active img, &:focus img {
      transform: scale(1.05);
    }
  }

  &__card-info {
   width: 47%;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__card-name {
    display: block;

    margin-bottom: 30px;

    color: #000;
    font-weight: bold;
    font-size: 18px;
    text-decoration: underline;

    transition: 0.3s;

    @include media-breakpoint-down(sm) {
      margin-bottom: 20px;
    }

    &:hover, &:active, &:focus {
      color: $main-color;
    }
  }

  &__card-text {
    margin-bottom: 25px;

    font-size: 14px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
    }
  }

  &__card-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .data-item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-right: 20px;
      }

      i, img {
        flex: none;

        margin-right: 15px;
      }

      i {
        font-size: 20px;
        color: $main-color;
      }
    }

    .data-info {
      display: flex;
      align-items: center;

      span {
        font-size: 14px;

        &.sum {
          margin-left: 10px;

          font-weight: bold;
        }
      }
    }
  }

  &__card-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 30px;

    @include media-breakpoint-down(lg) {
      padding: 20px;
    }

    @include media-breakpoint-down(md) {
      padding: 15px;
    }

    @include media-breakpoint-down(sm) {
     flex-direction: column;
    }
  }

  &__card-price {
    flex: none;
    display: flex;

    margin-right: 10px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;


      margin-right: 0;
      margin-bottom: 15px;
    }

    .name {
      margin-right: 25px;

      font-size: 14px;

      @include media-breakpoint-down(sm) {
        margin-right: 0;
        margin-bottom: 15px;
      }

    }

    .price-box {
      display: flex;
      align-items: center;

    }

    .price-item {
      display: flex;
      flex-direction: column;

      &.night {
        position: relative;

        padding-right: 10px;
        margin-right: 5px;

        &:before {
          content: "/";
          position: absolute;
          top: 0;
          right: 0;

          font-weight: bold;
        }
      }

      .price {
        font-size: 16px;
        font-weight: bold;
      }

      .day {
        font-size: 12px;
        color: $text-color-light;
      }
    }
  }


  &__card-decore {
    width: 80px;
    margin-bottom: 0;
    margin-right: 10px;

    @include media-breakpoint-down(sm) {
     display: none;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;
    }
  }
}