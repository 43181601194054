.content {
  font-size: 14px;

  h2, h3, h4 {
    text-align: left;
    margin-bottom: 35px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }

  h3 {
    font-size: 42px;

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }

  h4 {
    font-size: 32px;

    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }

  p, ul, ol {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      position: relative;

      padding-left: 25px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 9px;

        width: 7px;
        height: 7px;

        background-color: $main-color;
        border-radius: 50%;
      }
    }
  }
}