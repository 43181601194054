.services-page {


  &__slider {
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }
  }

  &__activity {
    .sub-title {
      justify-content: center;
    }

    .card-item__img {
      margin-bottom: 25px;

      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }
    }

    .card-item__text {
      margin-bottom: 25px;
      @include media-breakpoint-down(md) {
        margin-bottom: 15px;
      }

      p {
        font-size: 18px;

        @include media-breakpoint-down(md) {
          font-size: 14px;
        }
      }
    }
  }

  &__item {
    background-color: #fff;
    border-radius: 10px;
  }

  &__description {
    padding: 30px;

    @include media-breakpoint-down(md) {
      padding: 15px;
    }
  }

  &__img {
    position: relative;
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: auto;
      margin: 0 auto;
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover;  object-position: center;";
    }

    span {
      position: absolute;
      bottom: 10px;
      right: 10px;

      display: block;

      padding: 5px;

      font-size: 14px;
      font-weight: bold;
      border-radius: 5px;

      background-color: #fff;
    }
  }

  &__text {
    p,ul,ol {
      &:not(:last-child) {
        margin-bottom: 20px;

        font-size: 14px;
      }
    }
  }

  &__time {
    padding: 35px 30px;

    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;

    @include media-breakpoint-down(md) {
      padding: 15px;
    }

    h4 {
      margin-bottom: 20px;

      font-family: $default-font;
      font-weight: bolder;
      font-size: 14px;
    }

    .time-box {
      display: flex;
      align-items: center;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    .time-item {
      &:not(:last-child) {
        margin-right: 50px;

        @include media-breakpoint-down(sm) {
          margin-right: 0;

          margin-bottom: 20px;
        }
      }
      span {
        display: block;

        font-size: 14px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }

  &__gallery {

    padding: 30px;

    @include media-breakpoint-down(md) {
      padding: 15px;
    }

  }
}