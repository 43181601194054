.callback-form {
  padding: 100px 0;

  background-image: url("../img/callback-form-bg.jpg");
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include media-breakpoint-down(md) {
    padding: 50px 0;
  }

  &__item {
    position: relative;

    padding: 70px;

    background-color: #fff;

    border-radius: 8px;

    @include media-breakpoint-down(md) {
      padding: 30px;
    }

    &:before {
      content: "";
      position: absolute;
      top: -5px;
      right: 80px;
      width: 105px;
      height: 138px;

      background-image: url(../img/callback-decore-bg-mail.png), url(../img/callback-decore-bg.png);
      background-size: auto, contain;
      background-repeat: no-repeat;
      background-position: center, center;

      @include media-breakpoint-down(md) {
        right: 30px;

        width: 40px;
        height: 50px;

        background-size: 60%, contain;
      }

    }
  }

  form {
    display: flex;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    input, textarea {
      width: 100%;
    }

    textarea {
      height: 130px;

      @include media-breakpoint-down(md) {
        height: 100px;
      }
    }
  }

  &__left {
    flex: none;
    width: calc(34% - 35px);
    margin-right: 35px;

    @include media-breakpoint-down(md) {
      width: 100%;

      margin-right: 0;
      margin-bottom: 20px;
    }
  }


  &__right {
    width: 66%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      align-items: flex-start;

      flex-direction: column;
    }

    button {
      @include media-breakpoint-down(md) {
        align-self: center;
      }
    }
  }

  &__rules {
    flex: none;

    margin-right: 20px;

    @include media-breakpoint-down(sm) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }

  &__inner {
    &:not(:last-child) {
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }
    }
  }
}