.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  color: #fff;

  &.inner {
    position: relative;

    background-image: url("../img/header-inner-bg.jpg");
    background-color: $page-bg;
    background-position: center -10px;
    background-repeat: no-repeat;
    background-size: cover;

    @include media-breakpoint-down(md) {
      padding: 15px 0;

      background-position: center 0;
    }
  }

  @include media-breakpoint-down(md) {
    padding: 15px 0 0 0;
  }


  &:before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    bottom: 9px;
    z-index: 0;

    height: 60px;

    border-top: 1px solid transparentize(#fff, .5);
    border-bottom: 1px solid transparentize(#fff, .5);

    @include media-breakpoint-down(lg) {
      bottom: 0;
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &__box {
    display: flex;

    @include media-breakpoint-down(md) {
      align-items: center;
    }
  }

  &__logo {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: none;
    z-index: 1;

    width: 221px;
    height: 204px;

    margin-right: 65px;
    padding: 40px 15px 0 15px;

    background-image: url("../img/logo-bg.png");
    background-position: center top;
    background-repeat: no-repeat;
    background-size: contain;

    transform: translateY(20px);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: -20px;
      width: 100%;
      height: 20px;
      background-color: #fff;
    }

    img {
      display: block;
      max-width: 100%;
      width: 176px;
      height: auto;
      margin: 0 auto;
      object-fit: cover;

      @include media-breakpoint-down(lg) {
        width: auto;
      }
    }

    @include media-breakpoint-down(lg) {
      width: 150px;
      height: auto;
      margin-right: 35px;
    }

    @include media-breakpoint-down(md) {
      //background: none;
      padding: 0;
      background-position: 0 9px;

      transform: translateY(0);

      &:before {
        left: 28.5px;
        width: 62%;
       //display: none;
      }
    }

    @include media-breakpoint-down(xs) {
      width: 100px;
    }
  }

  &__item {
    flex: 1 1 auto;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: 25px;
    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      padding-top: 0;
    }

    @include media-breakpoint-down(sm) {
     justify-content: flex-end;
    }
  }

  &__address {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }

    i {
      margin-right: 20px;

      font-size: 23px;
      line-height: 1;
      color: $icon-color;
    }

    span {
      width: 255px;

      font-size: 13px;

      @include media-breakpoint-down(lg) {
        width: 200px;
      }
      @include media-breakpoint-down(md) {
        width: 300px;
      }
    }
  }

  &__tel {
    @extend .hover-line;

    display: flex;
    align-items: center;

    color: #fff;
    font-size: 22px;
    font-weight: 700;

    @include media-breakpoint-down(md) {
      font-size: 18px;
    }

    @include media-breakpoint-down(sm) {

      justify-content: center;

      margin-top: 20px;


    }

    .icon-box {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 20px;
      height: 20px;

      margin-right: 20px;

      border-radius: 50px;
      background-color: $icon-color;

      i {
        font-size: 10px;
        line-height: 1;
        color: #1e1e1c;

        @include media-breakpoint-down(sm) {
          color: $main-color;
        }
      }
    }

    .color {
      margin-right: 5px;

      color: $accent-color;
      font-weight: 400;
    }

    .tel-box {
      > span {
        display: block;
      }
    }
  }

  &__callback {
   @include media-breakpoint-down(md) {
     width: 170px;
     padding: 10px 15px;
   }

    @include media-breakpoint-down(xs) {

      width: 130px;

      i {
        display: none;
      }

    }
  }

  &__nav {

    @include media-breakpoint-down(md) {
      display: none;
      position: absolute;
      z-index: 3;

      top: 0;
      left: 0;
      right: 0;

      padding: 40px 15px ;

      background-color: transparentize($main-color, .03);

    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 20px 0;
      margin: 0;
      list-style: none;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: center;
      }
    }

    li {
      position: relative;

      padding: 0 30px;

      @include media-breakpoint-down(lg) {
        padding: 0 20px;
      }

      @include media-breakpoint-down(md) {
        padding: 0;

        margin-bottom: 15px;
      }


      &:before {
        content: "|";
        position: absolute;
        top: 2px;
        right: -2px;

        line-height: 1;

        font-weight: 200;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;

        &:before {
          display: none;
        }
      }

      a {
        @extend .hover-line;

        display: block;


        font-size: 13px;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
          text-decoration: underline;
        }

      }
    }

  }

  .header__menu-btn {
    display: none;
    position: relative;
    z-index: 4;

    width: 18px;
    height: 16px;

    margin-right: 15px;

    cursor: pointer;

    @include media-breakpoint-down(md) {
      display: block;
    }

    span, &:before, &:after {
      position: absolute;
      top: 7px;
      left: 0;

      width: 18px;
      height: 2px;

      background-color: $main-color;

      opacity: 1;
      transition: 0.3s;
    }

    &:before {
      content: "";
      top: 0;
    }

    &:after {
      content: "";
      bottom: 0;
      top: auto;
    }

    &.active {
      span {
        opacity: 0;
      }

      span, &:before, &:after {
        background-color: $accent-color;
      }

      &:before {
        transform: rotate(45deg) translate(5px, 4px);
      }

      &:after {
        transform: rotate(-45deg) translate(6px, -5px);
      }
    }

  }

}