.site-bar {

  @include media-breakpoint-down(md) {
    margin-top: 30px;

    order: 2;
  }

  &.another {
    .site-bar__item {
      padding: 25px 0;

      @include media-breakpoint-down(sm) {
        padding: 15px 0;
      }

      li {
        padding: 25px 20px 25px 35px;

        margin-bottom: 0;

        @include media-breakpoint-down(sm) {
          padding: 15px 15px 15px 35px;
        }

        &:not(:last-child) {
          border-bottom: 1px solid $border-color;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }

  &__item {
    @extend .shadow-l;

    padding: 25px 20px;

    background-color: #fff;
    border-radius: 10px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      a {
        display: inline-flex;
        align-items: center;

        font-size: 14px;
        color: $text-color;

        @include media-breakpoint-down(sm) {
          font-size: 12px;
        }
      }
    }
  }

  &__list {
    > li {
      position: relative;

      padding-left: 20px;


      &:not(:last-child) {
        margin-bottom: 13px;

        @include media-breakpoint-down(sm) {
          margin-bottom: 8px;
        }
      }

      > a {
        @extend .hover-line, .green;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 2px;
          left: -20px;

          width: 2px;
          height: 90%;

          background-color: $main-color;
        }

        i {
          margin-left: 10px;

          color: $main-color;
          font-weight: 600;
        }
      }
    }

    a {
      font-weight: bold;
    }

    .sub-list {
      display: none;

      padding-top: 10px;

      a {
        @extend .hover-line;
        font-weight: normal;
      }
    }
  }

  &__news {
    margin-top: 70px;

    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }

    h3 {
      margin-bottom: 25px;

      text-align: left;
      font-size: 18px;
      font-family: $default-font;
    }
  }

}