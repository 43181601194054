.activity-page {

  &__img {
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      margin: 0 auto;
      object-fit: cover;
    }
  }

  &__text {
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    p, ol, ul {
      margin-bottom: 0;

      font-size: 14px;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  &__gallery {
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
  }
}