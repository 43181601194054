.activity {

  &__row {
    margin-bottom: 20px;
  }

  &__col {
    &:not(:last-child) {
      margin-bottom: 50px;

      @include media-breakpoint-down(md) {
        margin-bottom: 30px;
      }
    }

    .card-item__text {
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
      }
    }
  }
}