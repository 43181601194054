@import "_service/vars";
@import "_service/fonts";
/*@import "_service/preloader";*/
@import "_service/libs";

::placeholder {
  color: $text-color-light;
}

::selection {
  background-color: $main-color;
  color: #fff;
}

body {
  font-size: 16px;
  min-width: 320px;
  position: relative;
  line-height: 1.65;
  font-family: $default-font;
  overflow-x: hidden;
  color: $text-color;

  &.active {
    background-color: green;
  }

  &.active-1 {
    background-color: blue;
  }

  input, textarea {
    height: 50px;

    padding: 0 10px;

    outline: none;
    border: 1px solid $border-color;
    border-radius: 0;

    &:focus:required:invalid {
      border-color: red;
    }

    &:required:valid {
      border-color: green;
    }
  }
  textarea {
    padding: 10px;
    resize: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: $title-font;
}

h1 {
  font-size: 68px;
  color: #fff;

}

h2 {
  font-size: 56px;
  text-align: center;

  @include media-breakpoint-down(md) {
    font-size: 40px;
  }
}

h3 {
  font-size: 32px;
  text-align: center;
}

figure {
  margin: 0;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;

  &.img-width {
    width: 100%;
  }
}

.text-center {
  text-align: center;
}

b, strong {
  font-weight: bold;
}

a.default-btn {
  color: #000;
}

.default-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 230px;
  max-width: 100%;

  padding: 17px 46px;

  font-size: 11px;
  text-transform: uppercase;
  font-weight: 700;
  color: #2a2a2a;

  background-image: $btn-color;

  border-radius: 28px;
  border: none;
  box-shadow: 0px 5px 0px #dd9a58;


  cursor: pointer;
  outline: none;

  @include media-breakpoint-down(md) {
    width: 200px;

    padding: 10px 20px;
  }


  &:hover, &:active, &:focus {
    background-image: $btn-color-hover;
    box-shadow: 0px 3px 0px #dd9a58;
    text-decoration: none;
    color: #2a2a2a;
  }

  i {
    margin-left: 25px;

    font-size: 18px;
    color: #685f4b;
  }
}

.hover-line {
  position: relative;
  display: inline-block;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;

    height: 1px;
    width: 0;

    background-color: $accent-color;

    transition: 0.3s;
  }

  transition: 0.3s;

  &:hover, &:active, &:focus {
    color: $accent-color;
    text-decoration: none;
  }

  &:hover:before, &:active:before, &:focus:before {
    width: 100%;
  }

  &.green {
    &:hover, &:active, &:focus {
      color: $main-color;
    }

    &:before {
      background-color: $main-color;
    }
  }
}

img.lazyload {
  display: block;
  width: 100%;
  height: auto;
  max-width: 100%;
  background-image: url("../img/lazy-img.gif");
}

.snail {
  display: inline-block;
  margin-right: 5px;
  max-width: 100%;

  img {
    display: block;
    max-width: 100%;
    width: auto;
    height: 18px;
  }

  &.px-16 {
    transform: translateY(6px);

    img {
      height: 18px;
    }
  }

  &.px-56 {
    img {
      height: 56px;

      @include media-breakpoint-down(md) {
        height: 48px;
      }
    }
  }

  &.px-68 {
    img {
      height: 58px;

      @include media-breakpoint-down(lg) {
        height: 48px;
      }
    }
  }
}

.page {
  padding: 60px 0 100px 0;

  background-color: $page-bg;

  background-image: url(../img/prices-bg-left.png),url(../img/prices-bg-right.png);
  background-size: auto,auto;
  background-repeat: no-repeat;
  background-position: left center, right center;

  @include media-breakpoint-down(md) {
    padding: 20px 0 50px 0;

    background-image: none;
  }
}

.sub-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 40px;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }

  span {
    font-size: 56px;
    line-height: 1;

    @include media-breakpoint-down(md) {
      font-size: 40px;
    }
  }

  a {
    @extend .hover-line, .green;

    display: flex;
    align-items: center;

    font-family: $default-font;
    font-size: 14px;
    color: $main-color;
    font-weight: bold;

    i {
      margin-left: 15px;
    }
  }
}

.main-table {
  overflow-x: auto;

  table {
    width: 100%;
    min-width: 500px;

    font-size: 14px;

    border-collapse: collapse;

    th, td {
      padding: 15px;

     &:not(:last-child) {
       border-right: 1px solid transparentize($border-color, .5);
     }
    }

    thead {
      background-color: $main-color;
      font-weight: 400;
      color: #fff;
    }

    tbody {
      tr {
        background-color: #fff;

        &:nth-child(even) {
          background-color: #f8fafa;
        }
      }
    }

  }
}


.checkbox:not(checked) {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.checkbox:not(checked) + label {
  position: relative;
  padding-left: 40px;

  font-size: 12px;

  a {
    color: $main-color;
    text-decoration: underline;
  }
}
.checkbox:not(checked) + label:before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid $border-color;
}

.checkbox:checked + label:after {
  content: '\f00c';
  font-family: "Font Awesome 5 Pro";
  font-size: 11px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 5px;
}

.come-back {
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;


  a {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 270px;
    max-width: 100%;

    padding: 25px 0;

    color: #000;
    font-size: 14px;
    font-weight: 500;

    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    transition: 0.3s;

    &:hover, &:active, &:focus {
      background-color: $main-color;

      text-decoration: none;
      color: #fff;

      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
    }
  }

  i {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
  }
}

.p--l {
  padding-left: 60px;

  @include media-breakpoint-down(md) {
    padding-left: 15px;
  }
}

.shadow-l {
  box-shadow: 0 0 10px 1px transparentize(#999, .9);
}

.scale-img {
  display: block;

  overflow: hidden;

  img {
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
    margin: 0 auto;

    transition: 0.3s;
  }

  &:hover img, &:active img, &:focus img {
    transform: scale(1.05);
  }
}

.dote-style {
  &.owl-carousel {
    .owl-nav {
      display: none; // Стрелки навигации для слайдера на главной

      width: 100%;
      height: 0;
      background-color: red;

      button {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        box-sizing: border-box;

        width: 50px; // обертка для стрелки, например фон
        height: 50px; // обертка для стрелки, например фон
        background-color: #fff;
        border-radius: 50%;

        outline: none;


        transform-origin: center center;

        transition: 0.3s;

        &:hover, &:active, &:focus {
          background-color: #000;
        }

        &:hover:before, &:active:before, &:focus:before {
          border-color: #fff;
          border-top-color: transparent;
          border-right-color: transparent;
        }

        &:before {
          content: "";

          position: absolute;
          top: calc(50% - 8px); // половина от ширины псевдоэлемента
          transform: translateY(-50%);

          width: 16px;
          height: 16px;

          background-color: transparent;
          color: #000;

          border: 2px solid #000;

          border-top-color: transparent;
          border-right-color: transparent;

          transition: 0.3s;
        }
      }

      .owl-prev {
        left: 15px;

        &:before {
          transform: rotate(45deg);
          left: 20px // шестая часть от ширины псевдоэлемента
        }
      }

      .owl-next {
        right: 15px;

        &:before {
          transform: rotate(225deg);
          right: 20px; // шестая часть от ширины псевдоэлемента
        }
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }
    }

    .owl-dots {
      position: absolute;
      bottom: 40px;

      width: 100%;

      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(md) {
        bottom: 20px;
      }

      .owl-dot {
        width: 15px;
        height: 15px;

        margin-right: 10px;

        border: 1px solid #fff;
        border-radius: 50%;

        outline: none;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          background-color: #fff;

          border: 1px solid #fff;
        }
      }
    }
  }
}

.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.choice-year {
  position: relative;

  width: 140px;

  background-color: #fff;

  &:before {
    content: "\f078";
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
    right: 15px;

    line-height: 1;

    z-index: 0;

    font-family: "Font Awesome 5 Pro";
  }

  &:after {
    content: "";
    position: absolute;

    left: -130px;
    top: 50%;
    transform: translateY(-50%);

    width: 100px;
    height: 1px;
    background-color: #f6bf40;

    @include media-breakpoint-down(sm) {
     display: none;
    }
  }

  @include media-breakpoint-down(sm) {
    margin-top: 15px;
  }
}

.my-custom-select {
  position: relative;
  display: inline-block;
  z-index: 1;

  width: 100%;
  height: 50px;

  padding: 10px 50px 10px 20px;

  font-weight: 400;
  line-height: 1.5;
  color: $text-color;
  vertical-align: middle;

  background-color: transparent;
  border: 1px solid $border-color;
  border-radius: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  @include media-breakpoint-down(sm) {
   height: 40px;

    padding: 5px 50px 5px 20px;
  }
}

.my-custom-select::-ms-expand {
  display: none;
}

@import "_component/header";
@import "_component/page-top";
@import "_component/site-bar";
@import "_component/callback-form";
@import "_component/card-item";
@import "_component/main-gallery";
@import "_component/content";

@import "_page/home";
@import "_page/prices";
@import "_page/placement";
@import "_page/placement-page";
@import "_page/services";
@import "_page/services-page";
@import "_page/activity";
@import "_page/activity-page";
@import "_page/about";
@import "_page/contacts";


@import "_component/footer";





