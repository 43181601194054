.card-item {
  display: flex;
  flex-direction: column;

  height: 100%;

  &__box {
    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
      padding-bottom: 20px;

      border-bottom: 1px solid $border-color;
    }
  }

  &__img {
    position: relative;

    display: block;

    margin-bottom: 15px;

    text-decoration: none;
    overflow: hidden;
    transition: 0.3s;

    &:hover, &:active, &:focus {
      text-decoration: none;
    }

    &:hover figure, &:active figure, &:focus figure {
      transform: scale(1.05);
    }

    figure {
      height: 250px;

      margin: 0;

      transition: 0.3s;
    }

    img {
      display: block;
      max-width: 100%;
      width: 100%;
      height: 100%;
      margin: 0 auto;

      object-fit: cover;
      object-position: center;

      font-family: " object-fit: cover; object-position: center;";
    }
  }

  &__date {
    position: relative;
    display: inline-flex;

    margin-bottom: 15px;

    align-items: center;

    i {
      margin-right: 15px;
    }

    span {
      font-size: 12px;
      font-weight: 300;
    }
  }

  &__text {
    margin-bottom: 15px;

    p {
      margin-bottom: 0;

      font-size: 14px;
    }
  }

  &__link {
    display: flex;
    align-items: center;

    margin-top: auto;

    color: $main-color;

    a {
      @extend .hover-line;
      @extend .green;

      margin-right: 10px;

      font-size: 14px;
      font-weight: 700;
      color: $main-color;
    }
  }
}