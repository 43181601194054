.placement-page {
  &__card {
    @extend .shadow-l;

    display: flex;
    align-items: center;

    margin-bottom: 40px;

    background-color: #fff;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;

      margin-bottom: 20px;
    }
  }

  &__slider {
    width: 65%;

    @include media-breakpoint-down(lg) {
      width: 55%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;

      margin-bottom: 20px;
    }

  }

  &__info {
    width: 35%;

    padding: 30px;

    @include media-breakpoint-down(lg) {
      width: 45%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;

      padding: 15px;
    }
  }

  .placement__card-price {
    flex-direction: column;

    margin-bottom: 40px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    .name {
      margin-right: 0;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }

      font-size: 14px;
      font-weight: bold;
    }

    .price-item {
      .price {
        margin-bottom: 10px;

        font-size: 28px;
        line-height: 1;

        @include media-breakpoint-down(sm) {
          font-size: 20px;
        }
      }

      &.night {
        padding-right: 15px;

        &:before {
          font-size: 28px;
          line-height: 1;

          @include media-breakpoint-down(sm) {
            font-size: 20px;
          }
        }
      }
    }
  }

  .placement__card-data {
    padding: 20px 0;
    margin-bottom: 40px;

    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;

    @include media-breakpoint-down(md) {
      padding: 10px 0;
      margin-bottom: 20px;
    }
  }

  &__reserve {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin-right: 10px;
    }

    .placement__card-decore {
      margin-right: 0;
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  &__include {
    @extend .shadow-l;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 50px;
    margin-bottom: 80px;
    background-color: #fff;
    border-radius: 10px;

    @include media-breakpoint-down(lg) {
      flex-wrap: wrap;

      padding: 25px;
      margin-bottom: 40px;
    }

    .include-name {
      flex: 0 1 auto;
      width: 13%;

      padding-bottom: 20px;
      margin-right: 15px;

      font-family: $title-font;
      font-size: 42px;
      line-height: 1;

      border-bottom: 2px solid $accent-color;

      @include media-breakpoint-down(lg) {
        width: 47%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;

        margin-bottom: 10px;
        margin-right: 0;
        padding-bottom: 10px;

        font-size: 25px;
      }
    }

    .include-item {
      width: 23%;

      &:not(:last-child) {
        margin-right: 15px;
      }

      @include media-breakpoint-down(lg) {
        width: 47%;

        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .include-inner {
      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &.disable {
        svg {
          fill: $text-disable;
        }

        span {
          color: $text-disable;
        }
      }

      svg {
        width: 20px;
        height: 20px;

        margin-right: 20px;

        fill: $main-color;

        transition: 0.3s;
      }

      span {
        font-size: 14px;
      }
    }
  }

  &__description {
    margin-bottom: 60px;

    @include media-breakpoint-down(md) {
      margin-bottom: 30px;
    }

    p, ul, ol {
      font-size: 14px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    h4 {
      font-size: 14px;
      font-weight: bold;
      font-family: $default-font;
    }
  }

}