.page-top {
  position: relative;

  padding: 55px 0 40px 0;

  background-color: $page-bg;

  border-bottom: 1px solid $border-color;

  &.not-border {
    border-bottom: none;
  }

  @include media-breakpoint-down(md) {
      padding: 20px 0;
  }

  .breadcrumbs {
    margin-bottom: 30px;
    overflow-x: auto;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    ul {
      display: flex;
      align-items: center;

      width: 470px;

      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;

        &:first-child {
          padding-left: 35px;

          &:before {
            content: "\f80c";
            position: absolute;
            top: 2px;
            left: 0;

            font-weight: bold;
            color: $main-color;
            font-size: 14px;

            font-family: "Font Awesome 5 Pro";
          }
        }

        &:not(:last-child) {
          padding-right: 30px;
          margin-right: 20px;

          &:after {
            content: "//";
            position: absolute;

            top: 0;
            right: 0;
          }

          a {
            text-decoration: underline;
          }
        }
      }

      a {
        color: $text-color-light;
        font-size: 12px;

        transition: 0.3s;

        &:hover, &:active, &:focus {
          color: $main-color;
        }
      }
    }
  }

  h1 {
    margin: 0;

    color: #000;
    line-height: 1;

    @include media-breakpoint-down(md) {
      font-size: 35px;
    }
  }

  &__date {
    position: absolute;
    transform: translateY(27px);

    display: inline-block;

    padding: 0 10px;

    background-color: $page-bg;

    @include media-breakpoint-down(md) {
      transform: translateY(8px);
    }

    .card-item__date {
      margin: 0;
    }
  }
}